import { CheckoutPageData } from '@/types/PageData';

const defaultImages = {
  background: '/members_web/page/header_green_background.jpg',
};

export const paymentMethods = {
  unknown: { value: 0, label: 'Unknown' },
  mastercard: {
    value: 1,
    label: 'Mastercard',
    logo: '/members_web/payments/mastercard.png',
  },
  visa: { value: 2, label: 'Visa', logo: '/members_web/payments/visa.png' },
  sepaDirectDebit: {
    value: 3,
    label: 'SEPA Credit Transfer',
    logo: '/members_web/payments/sepadirectdebit.png',
  },
  ideal: { value: 4, label: 'iDeal', logo: '/members_web/payments/ideal.png' },
  sofort: {
    value: 5,
    label: 'PayByBank',
    logo: '/members_web/payments/sofort.png',
  },
  bancontact: {
    value: 6,
    label: 'Bancontact',
    logo: '/members_web/payments/bancontact.png',
  },
  dankort: {
    value: 7,
    label: 'Dankort',
    logo: '/members_web/payments/dankort.png',
  },
  visaDankort: {
    value: 8,
    label: 'VisaDankort',
    logo: '/members_web/payments/visa.png',
  },
  mastercardComingSoon: {
    value: 9,
    label: 'Mastercard',
    logo: '/members_web/payments/mastercard.png',
  },
  carteBleue: {
    value: 10,
    label: 'CarteBleue',
    logo: '/members_web/payments/cartebleue.png',
  },
  carteBancaire: {
    value: 11,
    label: 'CarteBancaire',
    logo: '/members_web/payments/cartesbancaires.png',
  },
  nexi: { value: 12, label: 'Nexi', logo: '/members_web/payments/nexi.png' },
  payPal: {
    value: 13,
    label: 'PayPal',
    logo: '/members_web/payments/paypal.png',
  },
  amex: {
    value: 14,
    label: 'American Express',
    logo: '/members_web/payments/amex.png',
  },
  eps: { value: 15, label: 'EPS', logo: '/members_web/payments/eps.png' },
} as const;

const commonPaymentMethods = [paymentMethods.mastercard, paymentMethods.visa];

export const checkoutPageData: CheckoutPageData = {
  BE: {
    images: {
      ...defaultImages,
    },
    postalCodeValidator: '^[0-9]{4}$',
    paymentMethods: [
      paymentMethods.bancontact,
      paymentMethods.ideal,
      paymentMethods.sofort,
      paymentMethods.dankort,
      paymentMethods.visaDankort,
      paymentMethods.mastercardComingSoon,
      ...commonPaymentMethods,
    ],
  },
  DE: {
    images: {
      ...defaultImages,
    },
    postalCodeValidator: '^[0-9]{5}$',
    paymentMethods: [
      paymentMethods.payPal,
      paymentMethods.sofort,
      paymentMethods.ideal,
      paymentMethods.bancontact,
      paymentMethods.dankort,
      paymentMethods.visaDankort,
      paymentMethods.mastercardComingSoon,
      ...commonPaymentMethods,
      paymentMethods.sepaDirectDebit,
    ],
  },
  DK: {
    images: {
      ...defaultImages,
    },
    postalCodeValidator: '^[0-9]{4}$',
    paymentMethods: [
      paymentMethods.visa,
      paymentMethods.mastercard,
      paymentMethods.dankort,
      paymentMethods.visaDankort,
      paymentMethods.mastercardComingSoon,
      paymentMethods.ideal,
      paymentMethods.bancontact,
      paymentMethods.sofort,
    ],
  },
  FR: {
    images: {
      ...defaultImages,
    },
    postalCodeValidator: '^[0-9]{5}$',
    paymentMethods: [
      paymentMethods.visa,
      paymentMethods.mastercard,
      paymentMethods.carteBancaire,
      paymentMethods.carteBleue,
      paymentMethods.amex,
      paymentMethods.payPal,
    ],
  },
  GB: {
    images: {
      ...defaultImages,
    },
    postalCodeValidator:
      '^(([A-Za-z][0-9]{1,2})|(([A-Za-z][A-HJ-Ya-hj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-HJ-Ya-hj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2}$',
    paymentMethods: [
      paymentMethods.visa,
      paymentMethods.mastercard,
      paymentMethods.amex,
    ],
  },
  NL: {
    images: {
      ...defaultImages,
    },
    postalCodeValidator: '^[1-9][0-9]{3}\\s?[a-zA-Z]{2}$',
    paymentMethods: [
      paymentMethods.ideal,
      ...commonPaymentMethods,
      paymentMethods.bancontact,
      paymentMethods.sofort,
      paymentMethods.dankort,
      paymentMethods.visaDankort,
      paymentMethods.mastercardComingSoon,
    ],
  },
  AT: {
    images: {
      ...defaultImages,
    },
    postalCodeValidator: '^[0-9]{4}$',
    paymentMethods: [
      ...commonPaymentMethods,
      paymentMethods.eps,
      paymentMethods.ideal,
      paymentMethods.bancontact,
      paymentMethods.amex,
      paymentMethods.payPal,
      paymentMethods.sepaDirectDebit,
    ],
  },
  ES: {
    images: {
      ...defaultImages,
    },
    postalCodeValidator: '^[0-9]{5}$',
    paymentMethods: [
      ...commonPaymentMethods,
      paymentMethods.ideal,
      paymentMethods.sofort,
    ],
  },
};
