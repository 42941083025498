export { IconAmex } from './IconAmex';
export { IconBancontact } from './IconBancontact';
export { IconCarteBancaire } from './IconCarteBancaire';
export { IconDankort } from './IconDankort';
export { IconIdeal } from './IconIdeal';
export { IconMastercard } from './IconMastercard';
export { IconPaypal } from './IconPaypal';
export { IconSepa } from './IconSepa';
export { IconSofort } from './IconSofort';
export { IconVisa } from './IconVisa';
